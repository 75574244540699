pez.module('slideout-menu', function ($, $el) {
    pez.slideout = new Slideout({
        'panel': $('#panel').get(0),
        'menu': $('#slideout-menu').get(0),
        'tolerance': 70
    });

    $('a[href="#panel"]').click(function () {
        pez.slideout.toggle();
        return false;
    });
});