(function ($) {
    var pez = window.pez = {};
    var modules = {};

    pez.module = function (name, fn) {
        if (!modules[name]) {
            modules[name] = fn;
        } else {
            return console.error('Module already defined: ' + name);
        }
    };

    pez.bind = function ($modules, moduleName) {
        $modules.each(function () {
            var $mod = $(this);
            var name = moduleName ? moduleName : $mod.attr('data-module');


            if (!name) return console.error('Module name not defined');
            if (!modules[name]) return console.error('Module not found: ' + name);
            setTimeout(modules[name]($, $mod), 1);
        });
    };

    pez.params = function() {
        if(!pez._params) {
            var q = {};
            var queryString = location.search.substring(1);
            var re = /([^&=]+)=([^&]*)/g, m;
            m = re.exec(queryString);
            while (m) {
                q[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
                m = re.exec(queryString);
            }
            pez._params = q;
        }
        return pez._params;
    };
    
    $(function () {
        var $modules = $('[data-module]');
        pez.bind($modules);
    });
})(jQuery);
