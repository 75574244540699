pez.module('share', function ($, $el) {
    var $links = $el
        .find('li')
        .not('.mail,.print')
        .find('a');
    var $mail = $el.find('li.mail a');
    var $print = $el.find('li.print a');

    $links.click(function () {
        var $link = $(this);

        var data = $link.data();
        var href = $link.attr('href');
        var target = $link.attr('target') || '_social';
        var width = data.width || 500;
        var height = data.height || 150;
        var left = (screen.width / 2) - (width / 2);
        var top = (screen.height / 2) - (height / 2);

        window.open(href, target, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
        return false;
    });

    $print.click(function () {
        var $pdfLink = $('#pdf-link-anchor');
        if ($pdfLink.length) {
            window.location.href = $pdfLink.attr('href');
        } else {
            window.print();
        }

        return false;
    });


    $mail.click(function() {
        var title = "Check out this article from The Systems Thinker";
        var body = "Read the full article by clicking on this link: " + window.location;
        var href = "mailto:?subject=" + encodeURIComponent(title) + "&body=" + encodeURIComponent(body);
        window.open(href, "_email", "width=800, height=800");
        return false;
    });
    //$mail.attr("href", "mailto:?subject=Interesting%20information&amp;body=I thought you might find this information interesting:%20%0d%0a" + window.location);
});
