pez.module('sign-up', function($, $el) {
    var $form = $el.find('form');
    var $usernameField = $el.find('label:contains("User Name")').closest('.gfield').hide();

    function generateUUID(){
        var S4 = function() {
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return S4()+S4()+S4()+S4();
    }

    var username = 'u-' + generateUUID();
    $usernameField.find('input').val(username);
});
