pez.module('textualize', function ($, $el) {
    var $window = $(window);
    var baseW = $el.data().baseWidth || 1350;
    var basePx = 16;
    var minPx = $el.data().minPx || 15;
    var maxPx = $el.data().maxPx || 17;

    function resize() {
        var ratio = $el.width() / baseW;
        var px = basePx * ratio;
        px = Math.max(minPx, px);
        px = Math.min(maxPx, px);
        $el.css('font-size', px);

    }

    function load() {
        resize();
        setTimeout(function () {
            $el.animate({ opacity: 1 }, 200);
        }, 300);
    }

    $window.bind('resize', resize);
    load();
});