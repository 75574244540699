pez.module('body', function ($, $el) {
    var $win = $(window);
    var $body = $el.closest('.col-main');
    var $images = $el.find('.wp-caption,.st-image');
    var $alignedImage = $images.filter('.alignright,.alignleft,.alignnone');

    //config
    var config = $el.data();

    function load() {
        //remove empty p tags
        $el.find('p:empty').remove();
        $el.find('label:empty').remove();

        //bind iframes to resize kindly
        pez.bind($el.find('iframe'), 'iframe');
    }

    function rebind() {
        pez.bind($body.find('.cb-image[data-module]'));
    }

    function alignImages() {
        var bodyWidth = $body.width();
        $alignedImage.each(function () {
            var $img = $(this);
            $img.removeClass('dealign');
            var w = $img.width();
            var r = w / bodyWidth;
            if (r > 0.75) {
                $img.addClass('dealign');
            }
        });
    }


    function resize() {
        rebind();
        alignImages();
    }

    load();
    resize();
    $win.bind('load resize', resize);
    $alignedImage.each(function () {
        var img = $(this).get(0);
        if (img.onload) img.onload = resize;
    });
});