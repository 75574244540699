pez.module('square', function ($, $el)
{
    var $win = $(window);

    function resize()
    {
        $el.height($el.width());
        $el.animate({ opacity: 1 });
    }

    resize();
    $win.bind('load resize', resize);
});