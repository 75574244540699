pez.module('modal', function ($, $el) {
    var $body = $('body');
    var $allModals = $('[data-module="modal"]');
    var id = $el.attr('id');
    if (!id) return console.error('Modal elements require ids.');

    var sel = 'a[href=#' + id + ']';
    var $togglers = $(sel);

    function maybeClose(e) {
        var $targ = $(e.target);
        if (!$targ.is('.modal-tile') && !$targ.closest('.modal-tile').length) {
            close();
        }
    }

    function open() {
        if (pez.slideout) pez.slideout.close();
        $allModals.removeClass('visible');
        $el.addClass('visible');
        $el.find('input.tt-input').click().focus();
        $body.bind('click.modal', maybeClose);
        $body.bind('touchstart.modal', maybeClose);
    }

    function close() {
        $allModals.removeClass('visible');
        $body.unbind('click.modal');
        $body.unbind('touchstart.modal');
    }

    function toggle() {
        var visible = $el.hasClass('visible');
        if (visible) {
            close();
        } else {
            open();
        }
        return false;
    }

    $togglers.click(toggle);

    var p = pez.params();
    if(p.m && p.m == id) {
        open();
    }
    if($el.find('.gform_validation_error').length) {
        open();
    }
});
