pez.module('bookmark', function ($, $el) {
    var postId = $el.data().id;
    var ajaxurl = $el.data().ajaxurl;
    var $toggler = $el.find('.toggler');
    var $bookmarks = $('[data-module=bookmark][data-id=' + postId + ']');

    if (!postId || !ajaxurl) return;

    function check() {
        $.post(ajaxurl, {
            'action': 'st_bookmark_ping',
            'post': postId
        }, function (response) {
            if (response == 1) $el.addClass('bookmark-selected');
            $el.removeClass('loading');
        });
    }

    function toggle() {
        $bookmarks.toggleClass('bookmark-selected');
        var selected = $bookmarks.hasClass('bookmark-selected');
        ga( 'send', 'event', 'Favorite', 'Bookmarked', selected ? 'Selected' : 'Deselected' );
        $.post(ajaxurl, {
            'action': 'st_bookmark',
            'post': postId,
            'bookmarked': selected
        }, function (response) {
            //console.log(response);
        });
        return false;
    }

    check();
    $toggler.click(toggle);
});
