pez.module('iframe', function ($, $el) {
    var $win = $(window);
    var $iframes = $el.is('iframe') ? $el : $el.find('iframe');

    function resize() {
        $iframes.each(function () {
            var $iframe = $(this);
            var dh = parseInt($iframe.attr('height'));
            var dw = parseInt($iframe.attr('width'));
            var r = dw / dh;
            var w = $iframe.width();
            $iframe.height(w / r);
        });
    }

    $win.bind('load resize', resize);
});