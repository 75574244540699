pez.module('search', function ($, $el)
{
    var ajaxUrl = $el.data().typeahead;
    var results = new Bloodhound(
    {
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote:
        {
            url: ajaxUrl + '?action=ajax_search&fn=get_ajax_search&terms=%QUERY',
            wildcard: '%QUERY',
            rateLimitBy: 'throttle',
            rateLimitWait: 100
        }
    });

    $('input[name="s"]').typeahead(
    {
        minLength: 2,
        highlight: true
    }, {
        name: 'search',
        display: 'value',
        source: results.ttAdapter(),
        templates:
        {
            suggestion: function (data)
            {
                return data.excerpt;
            }
        }
    })
    .keypress(function (e)
    {
        if (13 == e.which)
        {
            $(this).parents('form').submit();
            return false;
        }
    });
});